import { css as emotion } from '@emotion/core';
import { createStyled, TStyled } from '@stitches/react';

import rubikRegularWoff from './rubik-regular.woff';
import rubikRegularWoff2 from './rubik-regular.woff2';
import rubikBoldWoff from './rubik-bold.woff';
import rubikBoldWoff2 from './rubik-bold.woff2';

// font face in emotion until https://github.com/modulz/stitches/issues/241 is fixed
// global styles in emotion until https://github.com/modulz/stitches/issues/305 is fixed
export const global = emotion`
  @font-face {
    font-family: "Rubik";
    src:
      url("${rubikRegularWoff2}") format("woff2"),
      url("${rubikRegularWoff}") format("woff");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Rubik";
    src:
      url("${rubikBoldWoff2}") format("woff2"),
      url("${rubikBoldWoff}") format("woff");
    font-weight: 700;
    font-style: normal;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: "";
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  #root {
    position: relative;
  }

  :root {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    min-height: 100%;
    height: 100%;
  }

  body {
    min-height: 100%;
    height: 100%;
    backgroundColor: #f9fafc; /* $consoleWhite */
  }

  body, table, button, input, textarea, select, option {
    font-family: "Rubik", sans-serif; /* $default */
    font-size: 2.2rem; /* $body */
    line-height: 3.6rem; /* $body */
  }

  h1 {
    font-size: 6.8rem; /* $h1 */
    line-height: 7.2rem; /* $h1 */
    font-weight: 700;
  }

  a {
    color: #25abff;
    text-decoration: none;
  }

  img, svg {
    display: block;
  }
`;

export const { css, styled } = createStyled({
  breakpoints: {
    retina: rule => `@media (-webkit-min-device-pixel-ratio: 2) { ${rule} }`,
    medium: rule => `@media (min-width: 640px) { ${rule} }`,
    large: rule => `@media (min-width: 960px) { ${rule} }`,
    full: rule => `@media (min-width: 1280px) { ${rule} }`,
    wide: rule => `@media (min-width: 1600px) { ${rule} }`
  },
  tokens: {
    colors: {
      // gray
      $pitchBlack: '#0c0c0d',
      $computerBlack: '#18181a',
      $carkol: '#212224',
      $chandelure: '#29292a',
      $perfectGray: '#2c2c2e',
      $zekrom: '#2b2b2e',
      $pancham: '#38383a',
      $pangoro: '#383a3b',
      $duskull: '#4b4a4b',
      $espurr: '#5a5a5e',
      $steelix: '#666767',
      $rhyhorn: '#7d7d7e',
      $dusclups: '#939496',
      $retroGray: '#aaaaad',
      $handheldMedium: '#a4a7b0',
      $dewgon: '#b4b4b7',
      $chargerGray: '#cacdd8',
      $reshiram: '#d7d7da',
      $pearl: '#dddddd',
      $superLight: '#e6e7ec',
      $extraLight: '#ededf0',
      $consoleWhite: '#f9fafc',

      // brand
      $brand50: '#fde7ed',
      $brand100: '#fcb9cd',
      $brand200: '#fa8aab',
      $brand300: '#f75b8a',
      $brand400: '#f62e6a',
      $brand500: '#f50049', // base
      $brand600: '#c9003b',
      $brand700: '#9b002e',
      $brand800: '#700021',
      $brand900: '#430013',
      $brand1000: '#170006',

      // blue
      $primary50: '#eaf7ff',
      $primary100: '#63e7ff',
      $primary200: '#9bd9ff',
      $primary300: '#74caff',
      $primary400: '#4dbaff',
      $primary500: '#25abff', // base
      $primary600: '#208dd0',
      $primary700: '#196da3',
      $primary800: '#124e74',
      $primary900: '#0a2e45',
      $primary1000: '#031018',

      // red
      $error50: '#fdedec',
      $error100: '#facac6',
      $error200: '#f7a6a0',
      $error300: '#f3837a',
      $error400: '#f06055',
      $error500: '#ee3d30', // base
      $error600: '#c33128',
      $error700: '#972720',
      $error800: '#6d1b17',
      $error900: '#41110d',
      $error1000: '#160504',

      // yellow
      $warning50: '#fcf4c0',
      $warning100: '#faed97',
      $warning200: '#fcf4c0',
      $warning300: '#f7e76e',
      $warning400: '#f6e045',
      $warning500: '#f4da1e', // base
      $warning600: '#c9b419',
      $warning700: '#9b8b13',
      $warning800: '#70640d',
      $warning900: '#433b09',
      $warning1000: '#171303',

      // green
      $success50: '#f3f9ed',
      $success100: '#daedcd',
      $success200: '#c3e0aa',
      $success300: '#aad68a',
      $success400: '#93ca68',
      $success500: '#7abe47', // base
      $success600: '#649d3a',
      $success700: '#4e7a2e',
      $success800: '#375721',
      $success900: '#213413',
      $success1000: '#0b1206'
    },
    fonts: {
      $default: 'Rubik, sans-serif'
    },
    fontSizes: {
      $h1: '6.8rem',
      $h2: '5.6rem',
      $h3: '4rem',
      $h4: '3rem',
      $body: '2.2rem',
      $small: '1.8rem'
    },
    lineHeights: {
      $h1: '7.2rem',
      $h2: '6.2rem',
      $h3: '5rem',
      $h4: '3.8rem',
      $body: '3.6rem',
      $small: '3rem'
    },
    radii: {
      $xsmall: '0.2rem',
      $small: '0.4rem',
      $medium: '0.8rem',
      $large: '1.2rem'
    }
  }
});

type Config = typeof styled extends TStyled<infer U> ? U : never;
export type Colors = keyof Config['tokens']['colors'];
