// libraries
import React, { ReactNode } from 'react';

import { styled } from 'style2';

interface Props {
  className?: string;
  href: string;
  level?: 'primary' | 'secondary';
  size?: 'medium' | 'small';
  kind?: 'neutral' | 'brand' | 'inverted';
  children: ReactNode;
}

export default function Button(props: Props) {
  const {
    className,
    level = 'primary',
    size = 'medium',
    kind = 'neutral',
    href,
    children
  } = props;

  return (
    <Wrapper
      className={className}
      level={level}
      size={size}
      kind={kind}
      href={href}
    >
      {children}
    </Wrapper>
  );
}

const Wrapper = styled('a', {
  display: 'inline-block',
  borderRadius: '5.4rem',
  whiteSpace: 'nowrap',
  transition:
    '125ms color ease, 125ms background-color ease, 125ms box-shadow ease',
  variants: {
    level: {
      primary: {
        backgroundColor: '$pitchBlack',
        color: '$consoleWhite',
        ':hover': {
          backgroundColor: '$consoleWhite',
          color: '$pitchBlack'
        }
      },
      secondary: {
        backgroundColor: 'transparent',
        color: '$pitchBlack',
        boxShadow: 'inset 0 0 0 0.2rem currentColor',
        ':hover': {
          boxShadow: 'inset 0 0 0 0.2rem transparent',
          backgroundColor: '$pitchBlack',
          color: '$consoleWhite'
        }
      }
    },
    kind: {
      neutral: {
        backgroundColor: '$pitchBlack'
      },
      brand: {
        backgroundColor: '$brand500'
      },
      inverted: {
        backgroundColor: '$consoleWhite',
        color: '$pitchBlack'
      }
    },
    size: {
      small: {
        height: '5rem',
        lineHeight: '5rem',
        padding: '0 3.8rem',
        fontSize: '$small'
      },
      medium: {
        height: '6.4rem',
        lineHeight: '6.4rem',
        padding: '0 5rem',
        fontSize: '$body'
      }
    }
  }
});

Wrapper.compoundVariant(
  { level: 'secondary', kind: 'neutral' },
  { backgroundColor: 'transparent' }
);

Wrapper.compoundVariant(
  { level: 'secondary', kind: 'inverted' },
  {
    backgroundColor: 'transparent',
    color: '$consoleWhite',
    ':hover': {
      boxShadow: 'inset 0 0 0 0.2rem transparent',
      backgroundColor: '$consoleWhite',
      color: '$pitchBlack'
    }
  }
);
