// libraries
import React, { useEffect, useState } from 'react';

// components
import Icon from 'components/Icon';
import Button from 'components2/Button';
import { styled } from 'style2';

import useMediaQuery from 'hooks/useMediaQuery';

// images
import logomarkBlack from 'images/logomarkBlack.svg';
import logomarkWhite from 'images/logomark.svg';
import logomarkBlackMono from 'images/logomarkBlackMono.svg';
import logomarkWhiteMono from 'images/logomarkWhiteMono.svg';

interface Props {
  className?: string;
  color?: 'dark' | 'light';
  mono?: boolean;
  legacy?: boolean;
}

export default function Navigation(props: Props) {
  const { className, color = 'dark', mono, legacy } = props;

  const [mounted, setMounted] = useState(false);
  const [open, setOpen] = useState(false);

  const isDesktop = useMediaQuery('screen and (min-width: 960px)'),
    isMobile = !isDesktop;

  useEffect(() => {
    setMounted(true);
    if (isDesktop) setOpen(false);
  }, [isDesktop]);

  const logos = mono ? LOGOS_MONO : LOGOS_COLOR;

  return !legacy || mounted ? (
    <Wrapper className={className}>
      <a href="/">
        <Logo src={logos[color]} alt="Parsec" />
      </a>
      <MenuButton
        color={color}
        onClick={e => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        <Icon name="menu" />
      </MenuButton>
      <NavWrapper open={open}>
        <MenuButton
          color="dark"
          onClick={e => {
            e.preventDefault();
            setOpen(false);
          }}
        >
          <Icon name="ex" />
        </MenuButton>
        <NavUl>
          <NavItem>
            <NavLink color={color} href="/features">
              Features
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink color={color} href="/teams">
              Teams
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink color={color} href="/warp">
              Warp
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink color={color} href="/sdk" dropdown>
              Developers
            </NavLink>

            <SubNav>
              <SubNavItem>
                <SubNavLink href="/sdk">Game Developers</SubNavLink>
              </SubNavItem>
              <SubNavItem>
                <SubNavLink href="/docs/sdk">SDK Documentation</SubNavLink>
              </SubNavItem>
              <SubNavItem>
                <SubNavLink href="/licensing">Enterprise Licensing</SubNavLink>
              </SubNavItem>
            </SubNav>
          </NavItem>
          <NavItem>
            <NavLink color={color} href="/about" dropdown>
              Company
            </NavLink>

            <SubNav>
              <SubNavItem>
                <SubNavLink href="/about">About</SubNavLink>
              </SubNavItem>
              <SubNavItem>
                <SubNavLink href="https://careers.parsec.app">Jobs</SubNavLink>
              </SubNavItem>
            </SubNav>
          </NavItem>
          <NavItem>
            <NavLink color={color} href="/login">
              Login
            </NavLink>
          </NavItem>
          <NavItem>
            <Button
              href="/downloads"
              size="small"
              level={mounted && isMobile ? 'primary' : 'secondary'}
              kind={isMobile || color === 'dark' ? 'neutral' : 'inverted'}
            >
              Download
            </Button>
          </NavItem>
        </NavUl>
      </NavWrapper>
    </Wrapper>
  ) : null;
}

const LOGOS_COLOR = {
  dark: logomarkBlack,
  light: logomarkWhite
};

const LOGOS_MONO = {
  dark: logomarkBlackMono,
  light: logomarkWhiteMono
};

const Wrapper = styled('header', {
  padding: '0 3rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: '$default'
});

const Logo = styled('img', {
  display: 'block',
  height: '3rem',
  margin: '3rem 0'
});

const NavWrapper = styled('nav', {
  transform: 'translateX(0)',
  position: 'fixed',
  left: '100%',
  right: 0,
  top: 0,
  width: '42rem',
  maxWidth: '80vw',
  height: '100vh',
  padding: '3rem',
  backgroundColor: '#ffffff',
  overflow: 'auto',
  transition: 'transform 500ms ease-in-out',
  zIndex: 2,
  large: {
    left: 'initial',
    overflow: 'visible',
    transform: 'none',
    position: 'relative',
    width: 'auto',
    height: 'auto',
    padding: 0,
    backgroundColor: 'transparent'
  },
  variants: {
    open: {
      true: {
        transform: 'translateX(-100%)'
      }
    }
  }
});

const NavUl = styled('ul', {
  listStyle: 'none',
  display: 'grid',
  gridAutoFlow: 'row',
  justifyItems: 'start',
  rowGap: '3rem',
  padding: 0,
  large: {
    gridAutoFlow: 'column',
    columnGap: '2rem',
    alignItems: 'center',
    transition: 'column-gap 125ms ease-in-out'
  },
  full: {
    columnGap: '4rem'
  }
});

const NavItem = styled('li', {
  position: 'relative'
});

const NavLink = styled('a', {
  position: 'relative',
  display: 'block',
  fontSize: '$small',
  lineHeight: '2.2rem',
  color: '$pitchBlack',
  large: {
    padding: '3rem 0',
    lineHeight: '$small'
  },
  variants: {
    color: {
      light: {
        large: {
          color: '$consoleWhite'
        }
      },
      dark: {
        color: '$pitchBlack'
      }
    },
    dropdown: {
      true: {
        display: 'flex',
        alignItems: 'center',
        '&::after': {
          content: "''",
          marginLeft: '0.7rem',
          marginTop: '0.2rem',
          width: '1.2rem',
          height: '1.2rem',
          display: 'block',
          border: '0.6rem solid transparent',
          borderTopColor: 'currentcolor'
        }
      }
    }
  }
});

const SubNav = styled('ul', {
  display: 'grid',
  gridAutoFlow: 'row',
  textAlign: 'left',
  height: 'auto',
  maxHeight: 0,
  opacity: 0,
  padding: 0,
  overflow: 'hidden',
  transition: 'max-height 250ms ease-in-out',
  ':hover': {
    opacity: 1,
    maxHeight: '20rem'
  },
  'li:hover > &': {
    opacity: 1,
    maxHeight: '20rem'
  },

  large: {
    position: 'absolute',
    maxHeight: 'initial !important',
    marginTop: '-1.4rem',
    padding: '1rem 0',
    boxShadow: '0 0.2rem 0.6rem rgba(0, 0, 0, 0.3)',
    transform: 'translateY(-1.6rem)',
    pointerEvents: 'none',
    backgroundColor: '$consoleWhite',
    borderRadius: '0.4rem',
    transition: 'transform 250ms ease-out, opacity 250ms ease',
    ':hover': {
      transform: 'translateY(0)',
      opacity: 1,
      pointerEvents: 'all'
    },
    'li:hover > &': {
      transform: 'translateY(0)',
      opacity: 1,
      pointerEvents: 'all'
    }
  }
});

const SubNavItem = styled('li', {
  '&:first-child': {
    marginTop: '3rem'
  },
  large: {
    '&:first-child': {
      margin: 0
    }
  }
});

const SubNavLink = styled('a', {
  display: 'block',
  whiteSpace: 'nowrap',
  fontSize: '$small',
  lineHeight: '$small',
  padding: '0.6rem 2rem',
  color: '$pitchBlack',
  transition: 'color 0.25s ease',
  '&:hover': {
    color: '$brand500'
  }
});

const MenuButton = styled('button', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '3rem',
  right: '3rem',
  width: '2.4rem',
  height: '2.4rem',
  padding: 0,
  border: 0,
  backgroundColor: 'transparent',
  cursor: 'pointer',
  zIndex: 1,
  large: {
    display: 'none'
  },
  variants: {
    color: {
      dark: {
        color: '$pitchBlack'
      },
      light: {
        color: '$consoleWhite'
      }
    }
  }
});
