// libraries
import React, { useEffect, useState } from 'react';

import { styled } from 'style2';

import facebook from 'images/facebook.svg';
import twitter from 'images/twitter.svg';
import youtube from 'images/youtube.svg';
import discord from 'images/discord.svg';
import logo from 'images/logo.svg';

interface Props {
  legacy?: boolean;
}

export default function Footer(props: Props) {
  const { legacy } = props;

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return !legacy || mounted ? (
    <Wrapper>
      <Content>
        <Meta>
          <Logo src={logo} alt="" />
          <p>
            © 2016-2021 Parsec Cloud, Inc.
            <br />
            All rights reserved.
          </p>

          <SocialList>
            <li>
              <a href="https://facebook.com/parsecapp" target="_blank" rel="noopener noreferrer">
                <SocialIcon src={facebook} alt="Facebook" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/parsecteam" target="_blank" rel="noopener noreferrer">
                <SocialIcon src={twitter} alt="Twitter" />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/c/ParsecGaming" target="_blank" rel="noopener noreferrer">
                <SocialIcon src={youtube} alt="YouTube" />
              </a>
            </li>
            <li>
              <a href="https://discordapp.com/invite/cQjEGFy" target="_blank" rel="noopener noreferrer">
                <SocialIcon src={discord} alt="Discord" />
              </a>
            </li>
          </SocialList>
        </Meta>

        <dl>
          <ListHeader>Product</ListHeader>
          <dd>
            <ListLink href="/features">Features</ListLink>
          </dd>
          <dd>
            <ListLink href="/teams">Parsec for Teams</ListLink>
          </dd>
          <dd>
            <ListLink href="/warp">Warp</ListLink>
          </dd>
          <dd>
            <ListLink href="/changelog">Changelog</ListLink>
          </dd>
          <dd>
            <ListLink href="/downloads">Downloads</ListLink>
          </dd>
        </dl>

        <dl>
          <ListHeader>Developers</ListHeader>
          <dd>
            <ListLink href="/sdk">Developers</ListLink>
          </dd>
          <dd>
            <ListLink href="/docs/sdk">SDK Documentation</ListLink>
          </dd>
          <dd>
            <ListLink href="/licensing">Licensing</ListLink>
          </dd>
        </dl>

        <dl>
          <ListHeader>Resources</ListHeader>
          <dd>
            <ListLink href="https://support.parsec.app">Support</ListLink>
          </dd>
          <dd>
            <ListLink href="https://blog.parsec.app">Blog</ListLink>
          </dd>
        </dl>

        <dl>
          <ListHeader>Company</ListHeader>
          <dd>
            <ListLink href="/about">About</ListLink>
          </dd>
          <dd>
            <ListLink href="https://careers.parsec.app">Jobs</ListLink>
          </dd>
          <dd>
            <ListLink href="/terms">Terms of Service</ListLink>
          </dd>
          <dd>
            <ListLink href="/privacy">Privacy Policy</ListLink>
          </dd>
        </dl>
      </Content>
    </Wrapper>
  ) : null;
}

const Wrapper = styled('footer', {
  padding: '12rem 4rem',
  backgroundColor: '$pitchBlack',
  fontFamily: '$default',
  fontSize: '$small',
  lineHeight: '$body',
  color: '$superLight'
});

const Content = styled('div', {
  maxWidth: '120.4rem',
  margin: '0 auto',
  display: 'grid',
  justifyContent: 'center',
  columnGap: '2rem',
  rowGap: '4rem',
  gridTemplateRows: 'auto auto',
  gridTemplateColumns: '1fr',
  medium: {
    gridTemplateColumns: '1fr 1fr 1fr'
  },
  large: {
    justifyItems: 'start',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '2fr 1fr 1fr 1fr 1fr'
  }
});

const Meta = styled('div', {
  gridRow: '1 / -1'
});

const Logo = styled('img', {
  width: '3rem',
  marginBottom: '2.6rem'
});

const ListHeader = styled('dt', {
  fontWeight: 'bold'
});

const ListLink = styled('a', {
  color: '$superLight'
});

const SocialList = styled('ul', {
  listStyle: 'none',
  display: 'grid',
  justifyContent: 'start',
  alignItems: 'center',
  gridAutoFlow: 'column',
  columnGap: '2rem',
  margin: '3rem 0 0',
  padding: 0
});

const SocialIcon = styled('img', {
  display: 'block',
  maxWidth: '2.4rem',
  maxHeight: '2.4rem'
});
